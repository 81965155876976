import React from 'react'

export default function ComingSoon() {
  return (
    <div className='upcoming-courses'>
        <h3 className='coming-soon'>Full Group Online Course (COMING SOON)</h3>
        <h3 className='coming-soon'>Full Group Face-to-Face Course (COMING SOON)</h3>
    </div>
  )
}
